<template>
  <b-overlay :show="formShow" rounded="sm" no-fade class=" mt-2">
    <b-card>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">User</th>
          </tr>
        </thead>
      </table>

      <validation-observer ref="userRules">
        <b-form>
          <b-row class="mt-2">
            <!-- Name -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group>
                <label for="Name">Name</label>
                <validation-provider name="Name" #default="{ errors }" rules="required">
                  <b-form-input id="name" type="text" v-model="userValue.name"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Surname -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Surname" label-for="surname">
                <validation-provider name="Surname" #default="{ errors }" rules="required">
                  <b-form-input id="surname" type="text" v-model="userValue.surname"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Email" label-for="email">
                <validation-provider name="Email" #default="{ errors }" rules="required|email">
                  <b-form-input id="email" type="text" :disabled="userId > 0" v-model="userValue.email"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12" sm="6" md="4" xl="3" v-if="userValue.id == null">
              <b-form-group label="Password" label-for="password">
                <validation-provider name="Password" #default="{ errors }" rules="required">
                  <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="3" v-else>
              <b-form-group label="Password" label-for="password">
                <b-form-input id="password" type="text" v-model="userValue.password"></b-form-input>
              </b-form-group>
            </b-col>

            <!-- Field: Role -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="User Role" label-for="role">
                <validation-provider name="role" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.role" :options="roleOptions" :value="roleOptions" label="name" :clearable="false" input-id="role" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Field: Status -->
            <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Status" label-for="status">
                <validation-provider name="status" #default="{ errors }" rules="required">
                  <v-select v-model="userValue.status" :options="statusOptions" :reduce="(val) => val.value" :clearable="false" input-id="status" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
              <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
            </b-col>

            <b-col cols="12" class="mt-5">
              <b-button v-if="userId == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
    </b-card>

    <b-card v-if="timeLines.length > 0">
      <app-timeline class="mt-2">
        <app-timeline-item v-for="item in timeLines" :key="item.id" :variant="varyant(item.status)">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ item.title }}</h6>
            <small class="text-muted">{{ dateFormat(item.created_at) }}</small>
          </div>
          <p v-if="item.surveyId != 0" style="cursor: pointer" @click="surveyClick(item.surveyId, item.status)">{{ 'Survey Id : #' + item.surveyId }}</p>
        </app-timeline-item>
      </app-timeline>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';

import store from '@/store';
import userStoreModule from '../userStoreModule';
import ImageCropper from '@core/components/image-cropper/ImageCropper.vue';
import { required, alphaNum, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import moment from 'moment';
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    vSelect,
    Cleave,
    ToastificationContent,
    ImageCropper,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var userValue = {
      name: null,
      surname: null,
      email: null,
      role: null,
      password: null,
      status: null,
    };

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Passive', value: 'passive' },
    ];

    return {
      userValue: userValue,
      timeLines: [],
      baseURL: store.state.app.baseURL,
      selectedImage: null,
      croppedImage: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      required,
      alphaNum,
      email,
      dropdownLoading: false,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      percentCompleted: 0,
      roleOptions: [],
      statusOptions,
      userId: null,
      formShow: false,
      permissions: [],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  created() {
    // this.getRoles();
    // this.getUserById();
    // this.timelineUserList();
  },

  watch: {},

  methods: {
    surveyClick(item, status) {
      if (item > 0 && status != 'D') {
        if (this.$Can('see_answer')) {
          router.push({ name: 'cpat-edit', params: { id: item } });
        }
      }
    },
    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'PA') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'success';
      } else if (val == 'B') {
        return 'info';
      } else if (val == 'C') {
        return 'warning';
      } else if (val == 'D') {
        return 'danger';
      } else if (val == 'E') {
        return 'warning';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'PA') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    getRoles() {
      store
        .dispatch('user/fetchRoles', [])
        .then((res) => {
          this.roleOptions = res.data.data.map((el) => {
            return el.name;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.userId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('user/fetchUser', { id: router.currentRoute.params.id })
          .then((response) => {
            this.userValue = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.userValue = undefined;
            }
          });
      } else {
        this.formShow = false;
      }
    },

    timelineUserList() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.userId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('user/timelineUserList', { id: router.currentRoute.params.id })
          .then((response) => {
            this.timeLines = response.data;
          })
          .catch((error) => {});
      } else {
        this.formShow = false;
      }
    },

    createNewDropdownValue(val) {
      this.dropdownLoading = true;
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.userRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store.dispatch('user/updateUser', this.userValue).then((response) => {
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ User Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                setTimeout(function() {
                  this.formShow = false;

                  // window.location.reload(true);
                  router.push({ name: 'user-list' });
                }, 100);
              }
            });
          } else {
            store
              .dispatch('user/userCheck', { email: this.userValue.email })
              .then((response) => {
                if (response.data == 'no') {
                  store.dispatch('user/addUser', this.userValue).then((response) => {
                    if (response.status == 200) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ User Add Successful',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });

                      setTimeout(function() {
                        this.formShow = false;
                        router.push({ name: 'user-list' });
                      }, 1000);
                    }
                  });
                } else {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'This email exists',
                      text: 'Please enter another email address.',
                      icon: 'RefreshCcwIcon',
                      variant: 'warning',
                    },
                  });
                }
              })
              .catch((error) => {});
          }
        } else {
          this.formShow = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
